@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

html.dark body {
  background: var(--background-default);
}

.font-thinVariation {
  font-weight: 300;
}
.font-regularVariation {
  font-weight: 400;
}
.font-boldVariation {
  font-weight: 700;
}

*::selection {
  background-color: var(--primary-500);
  color: white;
}

.animation-pulse {
  /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
  transform: scale(0.8);*/
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
      transform: scale(0.7);
      box-shadow: 0 0 0 0 #25D366;
  }

  70% {
      transform: scale(0.8);
      box-shadow: 0 0 0 40px rgba(229, 62, 62, 0);
  }

  100% {
      transform: scale(0.7);
  }
}

